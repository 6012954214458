@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}

.slick-slide > div {
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.active {
  color: #ff6700 !important;
}

a {
  text-decoration: none !important;
}
